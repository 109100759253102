/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Typography, Divider, Tooltip, SearchBar, DropdownMenu, ReclassifyMergeIcon } from 'ui';
import { GEOMETRY_TYPE, GEOMETRY_TYPES, GEOMETRY_TYPES_BY_INT, MAP_TYPE, Tool } from 'woodpecker';
import MoveIcon from 'ui/src/Assets/MoveIcon';
import CopyAndMoveIcon from 'ui/src/Assets/CopyAndMoveIcon';
import RotateIcon from 'ui/src/Assets/RotateIcon';
import DeleteIcon from 'ui/src/Assets/DeleteIcon';
import MergeIcon from 'ui/src/Assets/MergeIcon';
import AssignFeatureIcon from 'ui/src/Assets/AssignFeatureIcon';
import SVGpoint from 'ui/src/Assets/SVGpoint';
import SVGline from 'ui/src/Assets/SVGline';
import SVGpolygon from 'ui/src/Assets/SVGpolygon';
import Mousetrap from 'mousetrap';
import { detectUserOS, USER_OS } from 'utils';
import DownArrowOutlined from 'ui/src/Assets/DownArrowOutlined';
import useMapStore, { Layer } from '../../store/mapStore';
import { ACTION, ROTATE_OPTIONS, TOOL_TYPE } from '../../js-layer/toolLayer/constants';
import { executeAction, mapObj } from '../../js-layer/mapLayer/mapInit';
import {
  iconContainer,
  iconButton,
  disabled,
  layersSelect,
  ActionMenuContainer,
  layersDropdownContainer,
  selectMenuContainer,
  selectedStyle,
  StyledCode,
  StyledRotateLabelContainer,
  StyledRotateButton
} from './styles';
import { PARCEL } from '../../hooks/tools/helpers/constants';
import { HTMLENTITY_MAP, WIN_MAC_KEY_MAP } from '../../constants';

const Shortcut = ({ code = '' }) => {
  const [key1, key2] = code.split('+');
  const keyText1 = detectUserOS() === USER_OS.WIN ? key1 : HTMLENTITY_MAP[WIN_MAC_KEY_MAP[key1]];
  const keyText2 = HTMLENTITY_MAP[key2] ? HTMLENTITY_MAP[key2] : key2;
  return (
    <div>
      <StyledCode>{keyText1}</StyledCode>
      <span>&nbsp;+&nbsp;</span>
      <StyledCode>{keyText2}</StyledCode>
    </div>
  );
};

const RotateOptionLabel = ({ option }: { option: Tool }) => {
  return (
    <StyledRotateLabelContainer>
      {option.icon && <div className='action-icon'>{option.icon(false)}</div>}
      <div className='action-title'>
        <Typography variant='body3'>{option.title}</Typography>
      </div>
      <div className='action-shortcut'>
        <Shortcut code={option.shortcut} />
      </div>
    </StyledRotateLabelContainer>
  );
};

const renderSvg = {
  [GEOMETRY_TYPE.POINT]: (color: string) => <SVGpoint strokeColor={color} />,
  [GEOMETRY_TYPE.LINE]: (color: string) => <SVGline strokeColor={color} />,
  [GEOMETRY_TYPE.POLYGON]: (color: string) => <SVGpolygon strokeColor={color} />
};

interface SelectMenuProps {
  top: number;
  left: number;
  onClose: () => void;
  setSelectedTool: (tool: Tool) => void;
}

const SelectMenu: React.FC<SelectMenuProps> = ({ top, left, onClose, setSelectedTool }) => {
  const selectedFeatures = useMapStore(state => state.selectedFeatures);
  const setSelectedFeatures = useMapStore(state => state.setSelectedFeatures);
  const selectedTool = useMapStore(state => state.tool);
  const layers = useMapStore(state => state.layers);
  const isMultiSelectionEnable = useMapStore(state => state.toolsSetting?.allowSelectionAcrossLayers);
  const mapType = useMapStore(state => state.mapType);
  const [currentLayers, selectedLayerID] = useMapStore(state => [state.current_layers, state.selectedLayerID]);

  const [isAerialReclassify, setIsAerialReclassify] = useState(false);
  const [isAerialReclassifyAndMerge, setIsAerialReclassifyAndMerge] = useState(false);
  const [isRotateDropdown, setIsRotateDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [adjustedTop, setAdjustedTop] = useState(top);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const count = selectedFeatures?.length ?? 0;
  const isAerialMap = mapType === MAP_TYPE.AERIAL;

  useEffect(() => {
    if (isAerialMap) {
      Mousetrap.bind('z', () => setIsAerialReclassify(prev => !prev));
      Mousetrap.bind('shift+z', () => setIsAerialReclassifyAndMerge(prev => !prev));
    }
    return () => {
      Mousetrap.unbind('z');
      Mousetrap.unbind('shift+z');
    };
  }, [isAerialMap, isAerialReclassifyAndMerge]);

  useEffect(() => {
    if (isAerialReclassify && dropdownRef.current) {
      const dropdownHeight = dropdownRef.current.offsetHeight + 50;
      const screenHeight = window.innerHeight;
      const newTop = top + dropdownHeight > screenHeight ? screenHeight - dropdownHeight - 10 : top;
      setAdjustedTop(newTop);
    } else {
      setAdjustedTop(top);
    }
  }, [isAerialReclassify, top]);

  const vector_layer_id = useMemo(() => {
    if (!selectedFeatures?.length) return '';

    if (isAerialMap) {
      const feature = selectedFeatures[0];
      const properties = feature?.getProperties();
      const { vector_layer_id, temp_layer } = properties || {};
      return vector_layer_id || temp_layer;
    }

    const set = new Set<string>();
    for (let index = 0; index < selectedFeatures.length; index++) {
      const feature = selectedFeatures[index];
      const id = feature?.getProperties()?.vector_layer_id;
      if (layers.has(id)) set.add(id);
      else return '';
    }
    return [...set][0];
  }, [selectedFeatures, layers, isAerialMap]);

  const reclassifyLayerData = useMemo(() => {
    if (!isAerialMap || !selectedFeatures?.length) {
      return { isSameGeometryType: false, filteredLayers: [] };
    }

    const firstGeometryType = selectedFeatures[0].getGeometry()?.getType();
    const isSameGeometryType = selectedFeatures.every(
      feature => feature.getGeometry()?.getType() === firstGeometryType
    );

    if (!isSameGeometryType) {
      return { isSameGeometryType, filteredLayers: [] };
    }

    let filteredLayers: Layer[] = [];
    if (isMultiSelectionEnable) {
      filteredLayers = currentLayers.filter(layer => {
        return GEOMETRY_TYPES_BY_INT[layer?.geometry_type] === firstGeometryType && layer?.layer_type !== PARCEL;
      });
    } else if (selectedLayerID) {
      const selectedLayerGeometryType = mapObj.getLayerById(selectedLayerID)?.get('geometryType');
      if (selectedLayerGeometryType) {
        filteredLayers = currentLayers.filter(
          layer =>
            layer?.id !== selectedLayerID &&
            layer?.geometry_type === selectedLayerGeometryType &&
            layer?.layer_type !== PARCEL
        );
      }
    }

    return { isSameGeometryType, filteredLayers };
  }, [isAerialMap, selectedFeatures, selectedLayerID, currentLayers, isMultiSelectionEnable]);

  const SELECT_MENU_ITEMS: Tool[] = [
    {
      title: 'Delete',
      icon: () => <DeleteIcon />,
      tool_id: ACTION.DELETE,
      is_action: true,
      shortcut: 'd'
    },
    {
      title: 'Move',
      icon: () => <MoveIcon />,
      tool_id: TOOL_TYPE.MOVE,
      is_action: false,
      shortcut: 'm'
    },
    ...(isAerialMap
      ? [
          {
            title: 'Rotate',
            icon: () => <RotateIcon />,
            tool_id: '',
            is_action: true,
            dropdownMenu: ROTATE_OPTIONS
          }
        ]
      : []),
    {
      title: 'Copy and Move',
      icon: () => <CopyAndMoveIcon />,
      tool_id: TOOL_TYPE.COPY_AND_MOVE,
      is_action: false,
      shortcut: 'ctrl+m'
    }
  ];

  if (vector_layer_id) {
    SELECT_MENU_ITEMS.unshift({
      title: 'Reclassify',
      icon: () => <AssignFeatureIcon />,
      tool_id: TOOL_TYPE.PANEL_CLICK,
      is_action: false
    });
    if (isAerialMap)
      SELECT_MENU_ITEMS.push({
        title: 'Reclassify & Merge',
        icon: () => <ReclassifyMergeIcon />,
        tool_id: TOOL_TYPE.RECLASSIFY_MERGE,
        is_action: false
      });
    const geometry_type = layers?.get(vector_layer_id)?.geometry_type || 0;
    if (geometry_type === GEOMETRY_TYPE.POLYGON) {
      SELECT_MENU_ITEMS.push({
        title: 'Merge',
        icon: () => <MergeIcon />,
        tool_id: ACTION.MERGE,
        is_action: true,
        shortcut: 'c'
      });
    }
  }

  const handleClick = useCallback(
    (tool: Tool) => {
      setSelectedFeatures([...(selectedFeatures || [])]);
      if (tool.is_action) executeAction(tool.tool_id);
      else
        setSelectedTool({
          ...selectedTool,
          ...tool,
          clickId: Math.random().toString(36).substring(7),
          vector_layer_id,
          lastTool: { ...selectedTool }
        });

      onClose();
    },
    [selectedFeatures, setSelectedFeatures, selectedTool, vector_layer_id, onClose, setSelectedTool]
  );

  const handleAerialReclassify = (layer: any) => {
    setSelectedTool({
      ...selectedTool,
      ...{
        tool_id: 'reclassify',
        is_action: true,
        reclassify_layer_id: layer.id,
        shouldMergeAdjacentFeatures: isAerialReclassifyAndMerge
      },
      clickId: Math.random().toString(36).substring(7)
    });

    setTimeout(() => {
      setSelectedTool({ ...selectedTool, tool_id: TOOL_TYPE.SELECT });
      setIsAerialReclassifyAndMerge(false);
    }, 10);
  };

  const onToolClick = useCallback(
    (tool: Tool) => {
      const isReclassifyEnable = tool?.tool_id === TOOL_TYPE.PANEL_CLICK;
      const isReclassifyMergeEnable = tool?.tool_id === TOOL_TYPE.RECLASSIFY_MERGE;
      if (isAerialMap) {
        if (isReclassifyMergeEnable || isReclassifyEnable) {
          setIsAerialReclassify(isReclassifyEnable);
          setIsAerialReclassifyAndMerge(isReclassifyMergeEnable);
        }
      } else {
        handleClick(tool);
      }
    },
    [isAerialMap, handleClick]
  );

  const filteredSearchLayers = useMemo(() => {
    if (!searchQuery) return reclassifyLayerData?.filteredLayers || [];
    return reclassifyLayerData?.filteredLayers?.filter(layer =>
      layer?.layer_type?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, reclassifyLayerData?.filteredLayers]);

  const handleRotateClick = () => {
    setIsRotateDropdown(!isRotateDropdown);
  };

  const applySelectedStyle = (toolId: string) => {
    if (isAerialReclassify && toolId === TOOL_TYPE.PANEL_CLICK) return selectedStyle;
    if (isAerialReclassifyAndMerge && toolId === TOOL_TYPE.RECLASSIFY_MERGE) return selectedStyle;
    return false;
  };

  if (!selectedFeatures?.length) return <></>;

  const shouldDisableTool = (toolId: string) => {
    if (isAerialMap) {
      if (toolId === TOOL_TYPE.RECLASSIFY_MERGE) {
        return !selectedFeatures.every(f => f.getGeometry()?.getType() === GEOMETRY_TYPES.POLYGON);
      }
      if (toolId === TOOL_TYPE.PANEL_CLICK) {
        return !reclassifyLayerData?.isSameGeometryType && !reclassifyLayerData?.filteredLayers?.length;
      }
    }
  };

  return (
    <ActionMenuContainer top={adjustedTop} left={left}>
      <div className={selectMenuContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Typography variant='body5' color='#808080' sx={{ lineHeight: '1.125rem' }}>
            Selected
          </Typography>
          <Typography variant='body2' sx={{ lineHeight: '1.375rem' }}>
            {count} selected
          </Typography>
        </div>
        <Divider orientation='vertical' flexItem />
        <div>
          <Typography variant='body5' color='#808080' sx={{ lineHeight: '1.125rem' }}>
            Tools
          </Typography>
          <div className={iconContainer}>
            {SELECT_MENU_ITEMS?.map(tool => {
              let toolTipData = tool?.title;
              if (tool?.shortcut) toolTipData += ` ( ${tool?.shortcut?.split(' ').join('+')} )`;
              return (
                <Tooltip key={tool.tool_id} title={toolTipData}>
                  <div
                    className={`${iconButton} ${shouldDisableTool(tool?.tool_id) ? disabled : ''} ${applySelectedStyle(
                      tool?.tool_id
                    )}`}
                    onClick={e => {
                      if (tool.dropdownMenu) {
                        e.stopPropagation();
                      } else {
                        onToolClick(tool);
                      }
                    }}>
                    {tool.dropdownMenu ? (
                      <DropdownMenu
                        color='primary'
                        open={isRotateDropdown}
                        menuItems={ROTATE_OPTIONS.map(r => ({
                          label: <RotateOptionLabel option={r} />,
                          value: r.tool_id,
                          ...r
                        }))}
                        variant='filled'
                        onButtonClick={handleRotateClick}
                        handleClose={() => setIsRotateDropdown(false)}
                        onMenuItemClick={(_, item) => onToolClick(item as Tool)}
                        selected={{}}
                        renderButton={buttonProps => (
                          <StyledRotateButton type='button' {...buttonProps}>
                            {tool.icon ? tool.icon(false) : ''}
                            <DownArrowOutlined />
                            {buttonProps.content}
                          </StyledRotateButton>
                        )}
                        styleMenu={{
                          padding: '0px'
                        }}
                      />
                    ) : tool.icon ? (
                      tool.icon(false)
                    ) : (
                      ''
                    )}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
      {(isAerialReclassify || isAerialReclassifyAndMerge) && (
        <div ref={dropdownRef} className={layersDropdownContainer}>
          <div className='p-1'>
            <SearchBar
              placeholder='Search'
              onChangeFn={setSearchQuery}
              defaultValue=''
              width='100%'
              size='small'
              minChars={1}
            />
          </div>
          <div className={layersSelect}>
            {filteredSearchLayers?.map(layer => {
              return (
                <div
                  key={layer.id}
                  className='flex items-center gap-1 mt-1 pointer hover:bg-gray-100'
                  onClick={() => handleAerialReclassify(layer)}>
                  <div>{layer?.geometry_type && renderSvg[layer?.geometry_type](layer?.meta_data?.color)}</div>
                  <Typography variant='body2'>{layer.layer_type}</Typography>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ActionMenuContainer>
  );
};

export default SelectMenu;
