import React from "react";

const DownArrowOutlined = () => {
  return (
    <svg
      width="0.688rem"
      height="0.5rem"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50001 7.20996L0.333008 2.06396L1.56201 0.834961L5.50001 4.77296L9.43801 0.834961L10.667 2.06396L5.50001 7.20996Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default DownArrowOutlined;
